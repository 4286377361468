// @import 'codemirror/lib/codemirror';
// @import 'codemirror/theme/ttcn';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    color: #0a0a0a;
    // font-family: Poppins, sans-serif;
}

html, body { height: 100%; }
body {
    background-color: #fff;
    margin: 0;
}

.mainLayout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.background-decoration {
    background-image:
        url('./assets/bg/decor-left.svg'),
        url('./assets/bg/decor-right.svg');
    background-repeat: no-repeat;
    background-position: left 100%, right -60%;
    background-size: 20%, 25%;
    height: 100%;
    min-height: calc(100vh - 56px);
}

.skeleton {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    // background-image: linear-gradient(
    //     90deg,
    //     transparent 40%,
    //     rgba(255,255,255, 1),
    //     transparent 60%
    // );
    animation: shimmer 1000ms ease-in-out infinite alternate;
}

@media (prefers-color-scheme: light) {
    .skeleton::after {
        background-image: linear-gradient(
            90deg,
            transparent 40%,
            var(--mat-sidenav-content-background-color, #fff),
            transparent 60%
        );
    }
}

@media (prefers-color-scheme: dark) {
    .skeleton::after {
        background-image: linear-gradient(
            90deg,
            transparent 40%,
            var(--mat-sidenav-content-background-color, #303030),
            transparent 60%
        );
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.bone {
    display: block;
    background-color: rgba(0, 0, 0, 0.06);
    mix-blend-mode: difference;
}