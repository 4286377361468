@use '@angular/material' as mat;
@use 'generate-material-palette' as palette;

// @import '@angular/material/theming';
@include mat.core();

$md-app-primary-color: palette.createpalette('primaryPalette');
$md-app-accented-color: palette.createpalette('accentedPalette');
$md-app-warn-color: palette.createpalette('warnPalette');
$md-app-white-color: palette.createpalette('whitePalette');
$md-app-accented-dark-color: palette.createpalette('accentedDarkPalette');
$md-app-warn-dark-color: palette.createpalette('warnDarkPalette');

$custom-palette-primary: mat.m2-define-palette($md-app-primary-color);
$custom-palette-accent: mat.m2-define-palette($md-app-accented-color);
$custom-palette-warn: mat.m2-define-palette($md-app-warn-color);
$custom-palette-white: mat.m2-define-palette($md-app-white-color);
$custom-palette-accented-dark: mat.m2-define-palette($md-app-accented-dark-color);
$custom-palette-warn-dark: mat.m2-define-palette($md-app-warn-dark-color);

html {
  --mdc-shape-small: 0px !important;
  --mdc-filled-button-container-shape: 4px;
  --mdc-outlined-button-container-shape: 4px;
  --mdc-text-button-container-shape: 4px;
  --mdc-outlined-text-field-container-shape: 0px !important;
}

@media (prefered-color-scheme: light) {
  body .mat-mdc-flat-button.mat-primary, body .mat-mdc-unelevated-button.mat-primary {
    --mat-mdc-button-persistent-ripple-color: var(--color-primaryPalette-500-contrast) !important;
    --mdc-protected-button-label-text-color: var(--color-primaryPalette-500-contrast) !important;
    --mdc-filled-button-label-text-color: var(--color-primaryPalette-500-contrast) !important;
    --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1) !important;
  }

  body .mat-mdc-flat-button.mat-accent, body .mat-mdc-unelevated-button.mat-accent {
    --mat-mdc-button-persistent-ripple-color: var(--color-accentedPalette-500-contrast) !important;
    --mdc-protected-button-label-text-color: var(--color-accentedPalette-500-contrast) !important;
    --mdc-filled-button-label-text-color: var(--color-accentedPalette-500-contrast) !important;
    --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1) !important;
  }

  body .mat-mdc-flat-button.mat-warn, body .mat-mdc-unelevated-button.mat-warn {
    --mat-mdc-button-persistent-ripple-color: var(--color-warnPalette-500-contrast) !important;
    --mdc-protected-button-label-text-color: var(--color-warnPalette-500-contrast) !important;
    --mdc-filled-button-label-text-color: var(--color-warnPalette-500-contrast) !important;
    --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1) !important;
  }
}

// @media (prefers-color-scheme: dark) {
//   body .mat-mdc-outlined-button:not(:disabled) {
//     --mdc-outlined-button-label-text-color: #fff !important;
//   }

//   // body .mat-mdc-button:not(:disabled) {
//   //   --mdc-text-button-label-text-color: #fff !important;
//   // }
// }

// .mat-mdc-checkbox .mdc-form-field {
//   --mdc-checkbox-selected-checkmark-color: var(--color-accentedPalette-500-contrast) !important;
// }

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  --mdc-checkbox-selected-checkmark-color: var(--color-accentedPalette-500-contrast) !important;
}

.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f;
}

.mat-elevation-z2 {
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px #00000024,0 1px 5px #0000001f;
}

$button-custom-level: mat.m2-define-typography-level(
  $font-family: 'Noto Sans',
  $font-weight: 500,
  $font-size: 14px,
  $line-height: 34px,
  $letter-spacing: normal,
);

$body-1-custom-level: mat.m2-define-typography-level(
  $font-family: 'Noto Sans',
  $font-weight: 400,
  $font-size: 14px,
  $line-height: 20px,
  $letter-spacing: normal,
);

$caption-custom-level: mat.m2-define-typography-level(
  $font-family: 'Noto Sans',
  $font-weight: 400,
  $font-size: 12px,
  $line-height: 16px,
  $letter-spacing: normal
);

$custom-typography-config: mat.m2-define-typography-config(
    $font-family: 'Noto Sans',
    $button: $button-custom-level,
    $body-1: $body-1-custom-level,
    $caption: $caption-custom-level,
);


$custom-light-theme: mat.m2-define-light-theme((
  color: (
    primary: $custom-palette-primary,
    accent: $custom-palette-accent,
    warn: $custom-palette-warn,
  ),
 density: -3,
));

$custom-dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $custom-palette-white,
    accent: $custom-palette-accented-dark,
    warn: $custom-palette-warn-dark,
  ),
  density: -3,
));

body {
  @include mat.button-density(0);
}

@media (prefers-color-scheme: light) {
  :root {
    @include mat.all-component-themes($custom-light-theme);
    @include mat.all-component-typographies($custom-typography-config);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @include mat.all-component-themes($custom-dark-theme);
    @include mat.all-component-typographies($custom-typography-config);
  }
}
